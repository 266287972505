import React, { useMemo, useState, useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import { Button } from 'antd';
import ApplicationsDashboard from './Dashboard/ApplicationsDashboard';
import LayoutPage from 'components/Layout';
import LoadingPage from 'components/LoadingPage';
import ProtectedRoute from 'components/ProtectedRoute';
import CheckPermissions from 'components/CheckPermissions';
import FeeCalculator from 'containers/Family/FeeCalculator';
import EmailPreviewPage from 'components/EmailPreviewPage';
import DocumentsPreviewPage from './DocumentsReview/DocumentsPreviewPage';
import { isFamilyRole, ScrollContext } from 'utils';
import { logout, selectActiveRole, selectRoles } from 'features/auth';
import { ROLES } from 'constants/index';
import FamilyHouseHoldInfo from './FamilyApplication/FamilyHouseHoldInfo';
import Alerts from 'components/Alerts';
import AllEmails from 'components/AllEmails';
import FamilyApplicationReview from './FamilyApplication/FamilyApplicationReview';
import ProviderProfile from 'containers/Provider/ProviderProfile';
import { Calculator, Finance, Logout } from '@carbon/icons-react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import { getAdminMenuOptions } from 'containers/Admin/Admin';
import ProviderApplication from './ProviderApplication/ProviderApplication';
import ProviderApplicationNotes from './ProviderApplication/ProviderApplicationNotes';
import MyAccount from 'containers/MyAccount/MyAccount';

export default function Internal(props) {
  const { history, location } = props;
  const dispatch = useDispatch();

  const scrollEnabled = useMemo(
    () => /^\/applications\/\d+$|^\/applications\/\d+\/review/.test(location?.pathname),
    [location?.pathname],
  );
  const activeRole = useSelector(selectActiveRole);
  const roles = useSelector(selectRoles);

  const [scrollHidden, setScrollHidden] = useState(false);

  const menuOptions = useMemo(() => {
    const _options = getInternalMenuItems({ activeRole, dispatch, history });

    if (roles.includes(ROLES.SYSTEM_ADMIN)) {
      return [..._options, ...getAdminMenuOptions()];
    }

    return _options;
  }, [activeRole, dispatch, history, roles]);

  const _logout = useCallback(async () => {
    dispatch(logout.pending());
  }, [dispatch]);

  return (
    <ScrollContext.Provider value={[scrollHidden, setScrollHidden, scrollEnabled]}>
      <LayoutPage
        {...props}
        menuOptions={menuOptions}
        headerIcons={
          <Button
            type="text"
            icon={<Logout />}
            style={{
              color: '#FFFFFF',
            }}
            onClick={_logout}
          >
            Logout
          </Button>
        }
        scrollClassName={classNames({
          'h-[calc(100vh-50px)] overflow-hidden sticky top-0': scrollEnabled,
          'overflow-auto': !scrollEnabled,
        })}
      >
        <React.Suspense fallback={<LoadingPage />}>
          <CheckPermissions
            requiredRoles={[
              ROLES.PROVIDER_MANAGER,
              ROLES.PROVIDER_QC_MANAGER,
              ROLES.PROVIDER_REVIEWER_MANAGER,
              ROLES.PROVIDER_COORDINATOR,
              ROLES.PROVIDER_QC_COORDINATOR,
              ROLES.PROVIDER_REVIEWER,
              ROLES.SYSTEM_ADMIN,
            ]}
          >
            <ProviderRoutes roles={[activeRole]} activeRole={activeRole} />
          </CheckPermissions>

          <CheckPermissions
            requiredRoles={[
              ROLES.FAMILY_MANAGER,
              ROLES.FAMILY_COORDINATOR,
              ROLES.FAMILY_QC_MANAGER,
              ROLES.FAMILY_QC_COORDINATOR,
              ROLES.ER_MANAGER,
              ROLES.ER_COORDINATOR,
              ROLES.FAMILY_SUPPORT_MANAGER,
              ROLES.REPORTING_MANAGER,
              ROLES.REPORTING_ANALYST,
              ROLES.FAMILY_SUPPORT_SPECIALIST,
              ROLES.FINANCE_MANAGER,
              ROLES.PARENT_SERVICES_MANAGER,
              ROLES.PARENT_SERVICES_SPECIALIST,
              ROLES.PROVIDER_SUPPORT_SPECIALIST,
              ROLES.SYSTEM_ADMIN,
            ]}
          >
            <FamilyRoutes roles={[activeRole]} activeRole={activeRole} />
          </CheckPermissions>

          <CheckPermissions hideFor={[ROLES.FAMILY, ROLES.PROVIDER]}>
            <InternalUsersRoute activeRole={activeRole} />
          </CheckPermissions>

          {/* </Switch> */}
        </React.Suspense>
      </LayoutPage>
    </ScrollContext.Provider>
  );
}

function ProviderRoutes({ activeRole }) {
  return <Switch></Switch>;
}

function FamilyRoutes({ activeRole }) {
  return (
    <Switch>
      <ProtectedRoute exact path="/fee-calculator" message="Navigated to Fee Calculator Page">
        <FeeCalculator />
      </ProtectedRoute>
    </Switch>
  );
}

function InternalUsersRoute({ activeRole }) {
  return (
    <Switch>
      <ProtectedRoute path="/account" message="Navigated to My account">
        <MyAccount />
      </ProtectedRoute>

      <ProtectedRoute path="/providers/applications/:id/notes" message="Navigated to Application Notes">
        <ProviderApplicationNotes />
      </ProtectedRoute>
      <ProtectedRoute path="/providers/applications/:id" message="Navigated to Application Details Page">
        <ProviderApplication />
      </ProtectedRoute>
      <ProtectedRoute path="/providers/public/:id" message="Navigated to Provider Details Page">
        <ProviderProfile />
      </ProtectedRoute>
      <ProtectedRoute exact path="/documents/:documentId" message="Navigated to Documents Preview page">
        <DocumentsPreviewPage className="m-5 shadow-md" />
      </ProtectedRoute>
      <ProtectedRoute exact path="/messages/:messageId" message="Navigated to Communication Details Page">
        <EmailPreviewPage />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={['/families', '/providers', '/families/search', '/providers/search']}
        message="Navigated to  Applications Dashboard"
      >
        <ApplicationsDashboard />
      </ProtectedRoute>
      <ProtectedRoute exact path={['/dashboard']} message="Navigated to  Dashboard">
        <Redirect to="/providers" />
      </ProtectedRoute>
      <ProtectedRoute exact path={['/messages']} message="Navigated to  Messages Page">
        <AllEmails className="p-6" />
      </ProtectedRoute>
      <ProtectedRoute exact path={['/alerts']} message="Navigated to  Alerts Page">
        <Alerts className="p-6" />
      </ProtectedRoute>
      <ProtectedRoute path="/families/:householdId/applications/:id/review" message="Navigated to Family Details Page">
        <FamilyApplicationReview />
      </ProtectedRoute>
      <ProtectedRoute path="/families/:householdId" message="Navigated to Family Details Page">
        <FamilyHouseHoldInfo />
      </ProtectedRoute>
    </Switch>
  );
}

export function getInternalMenuItems({ activeRole, dispatch, history }) {
  const items = [
    {
      label: 'Provider queue',
      to: '/providers',
      match: ['/providers', '/providers/search'],
      icon: Finance,
      onClick: () => {
        history.push('/providers');
      },
    },
  ];

  if (isFamilyRole(activeRole)) {
    items.push({
      label: 'Fee Calculator',
      icon: Calculator,
      to: '/fee-calculator',
      match: ['/fee-calculator'],
    });
  }

  return items;
}
