import Space, { Spacer } from 'components/Space/Space';
import React, { useState, useEffect } from 'react';
import { List, Button, Card, message, Typography, Form, Input, Divider, ConfigProvider } from 'antd';
import { useAsyncCallback } from 'react-async-hook';
import Html from 'Html';
import { familyService, providerService } from 'services';
import { ENTITY_TYPES } from 'constants/index';
import { formatDateTimeToLocal, getFullName, htmlToText } from 'utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function ProviderApplicationNotesCard({
  className,
  applicationId,
  entityType = ENTITY_TYPES.CARE_FACILITY,
  mode,
}) {
  const history = useHistory();

  const isCompact = mode === 'compact';

  // Pagination
  const [paginationParams, setPaginationParams] = useState({
    draft: false,
    page: 0,
    size: 5,
  });

  const [notes, setNotes] = useState([]);
  const { loading, execute: getNotesWithPagination } = useAsyncCallback(
    async (paginationParams) => {
      try {
        if (entityType === ENTITY_TYPES.CARE_FACILITY) {
          if (!applicationId) {
            setNotes([]);
            return;
          }

          const _notes = await providerService.getApplicationNotes(applicationId, {
            ...paginationParams,
            sort: 'id,desc',
          });

          setNotes(_notes);
        } else {
          if (!applicationId) {
            setNotes([]);
            return [];
          }

          const _notes = await familyService.getNotes(applicationId, { ...paginationParams, sort: 'id,desc' });

          setNotes(_notes);
        }
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to get notes.', 5);
        return [];
      }
    },
    [applicationId],
  );

  useEffect(() => {
    getNotesWithPagination(paginationParams);
  }, [applicationId, getNotesWithPagination, paginationParams]);

  const onPageChange = (page, size) => {
    const params = {
      ...paginationParams,
      page: page - 1,
      size,
    };

    setPaginationParams(params);
  };

  // Adding a new note
  const [form] = Form.useForm();
  const [showAddNoteForm, setShowAddNoteForm] = useState(false);
  const [newNoteSaving, setNewNoteSaving] = useState(false);
  const saveNote = async (values) => {
    setNewNoteSaving(true);

    try {
      await providerService.saveNote(applicationId, { content: values.newNote, draft: false });

      // show success message
      message.success('Your note has been saved');

      // reset and hide form
      setShowAddNoteForm(false);
      form.resetFields();

      // refetch notes
      getNotesWithPagination(paginationParams);
    } catch (error) {
      newrelic.noticeError(error);
      console.log('Error saving note', error);

      // show error message
      message.error('Unable to add note');
    } finally {
      setNewNoteSaving(false);
    }
  };

  return (
    <>
      {!isCompact && (
        <Typography.Title level={1} className="mt-8">
          Notes
        </Typography.Title>
      )}

      <Card className={className}>
        {isCompact && (
          <div className="flex items-center mb-6">
            <Typography.Title level={3} className="!m-0">
              Notes
            </Typography.Title>

            <Spacer />

            <Button
              onClick={() => {
                setShowAddNoteForm(true);
              }}
            >
              Add note
            </Button>
          </div>
        )}

        {(!isCompact || showAddNoteForm) && (
          <Form onFinish={saveNote} form={form} layout="vertical">
            <Form.Item name="newNote" label="Add note" rules={[{ required: true, message: 'This is required' }]}>
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                placeholder="Write your note here"
                autoComplete="off"
                autoFocus
              />
            </Form.Item>

            <Space size={2}>
              <Button type="primary" loading={newNoteSaving} htmlType="submit">
                Save
              </Button>

              {isCompact && (
                <Button
                  disabled={newNoteSaving}
                  onClick={() => {
                    setShowAddNoteForm(false);
                  }}
                >
                  Cancel
                </Button>
              )}
            </Space>

            <Divider className="mb-0" />
          </Form>
        )}

        <ConfigProvider renderEmpty={() => <div>No notes</div>}>
          <List
            itemLayout="vertical"
            size="large"
            pagination={
              notes?.content?.length !== 0
                ? {
                    pageSize: paginationParams.size,
                    total: isCompact ? paginationParams.size : notes?.totalElements,
                    onChange: onPageChange,
                    showSizeChanger: true,
                    hideOnSinglePage: isCompact,
                    pageSizeOptions: ['5', '10', '20', '50', '100'],
                  }
                : false
            }
            dataSource={notes.content}
            loading={loading}
            renderItem={(item) => {
              const createdBy = getFullName(item.createdBy);

              return (
                <List.Item key={item.id} className="!px-0">
                  <p style={{ lineHeight: '10px' }} className="text-xs text-gray-500">
                    {formatDateTimeToLocal(item.lastModifiedDate)}
                  </p>

                  <div className="mt-2">
                    <span className="text-sm font-semibold">{createdBy || 'NA'}</span>{' '}
                    <span>
                      <Html className="inline" html={htmlToText(item.content)} />
                    </span>
                  </div>
                </List.Item>
              );
            }}
          />
        </ConfigProvider>

        {isCompact && notes?.content?.length !== 0 && (
          <div className="flex justify-end">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();

                history.push(`/providers/applications/${applicationId}/notes`);
              }}
            >
              View all
            </a>
          </div>
        )}
      </Card>
    </>
  );
}
