import { useParams } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { Breadcrumb } from 'antd';
import PageMeta from 'components/PageMeta/PageMeta';
import { providerService } from 'services';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ENTITY_TYPES } from 'constants';
import ProviderApplicationNotesCard from './ApplicationDetails/ProviderApplicationNotesCard';

export default function ProviderApplicationNotes() {
  const { id } = useParams();
  const history = useHistory();

  const [application, setApplication] = useState(null);
  const fetchApplication = useCallback(() => {
    providerService
      .getApplication(id)
      .then((data) => {
        setApplication(data);
      })
      .catch((error) => {
        console.error('Error fetching application', error);
      });
  }, [id]);

  useEffect(() => {
    fetchApplication();
  }, [fetchApplication, id]);

  return (
    <div className="min-h-screen">
      <PageMeta title="Application Details" />

      <Breadcrumb separator=">">
        <Breadcrumb.Item
          onClick={() => {
            history.push('/providers');
          }}
          className="cursor-pointer underline decoration-primary"
        >
          <span className="text-primary">Provider application queue</span>
        </Breadcrumb.Item>

        {!!application && (
          <>
            <Breadcrumb.Item
              onClick={() => {
                history.push(`/providers/applications/${application?.id}`);
              }}
              className="cursor-pointer underline decoration-primary"
            >
              <span className="text-primary">
                {application?.careFacility?.businessLegalName || application?.careFacility?.doingBusinessAs || 'N/A'}
              </span>
            </Breadcrumb.Item>

            <Breadcrumb.Item>Notes</Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>

      <ProviderApplicationNotesCard entityType={ENTITY_TYPES.CARE_FACILITY} applicationId={id} />
    </div>
  );
}
