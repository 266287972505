import axios from 'axios';
import qs from 'qs';
import config from 'config';
import { setupCache } from './interceptors';

const instance = axios.create({ baseURL: `${config.API_BASE_URL}/users` });
const request = setupCache(instance);

const api = {
  getCurrentUser: async () => {
    const response = await request.get('/current');
    const { data } = response;
    return data;
  },
  getUserById: async (id, options) => {
    const { data } = await request.get(`/${id}`, options);
    return data;
  },
  getUsers: async (params = {}) => {
    const response = await request.get('/all', {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    const { data } = response;
    return data;
  },

  updateUser: async (id, payload) => {
    const response = await request.put(`/${id}`, payload);
    const { data } = response;
    return data;
  },
  addUser: async (params) => {
    const response = await request.post(``, params);
    const { data } = response;
    return data;
  },
  resendConfirmationEmail: async (payload) => {
    const response = await request.post(`/resend-confirmation`, payload);
    const { data } = response;
    return data;
  },
  adminResendConfirmationEmail: async (payload) => {
    const response = await request.post(`/admin/resend-confirmation`, payload);
    const { data } = response;
    return data;
  },
  resendInvite: async (payload) => {
    const response = await request.post(`/admin/resend-invite`, payload);
    const { data } = response;
    return data;
  },
  changeUserName: async (payload) => {
    const response = await request.post(`/change-username`, payload);
    const { data } = response;
    return data;
  },
  adminCreateUser: async (params) => {
    const response = await request.post(`/admin`, params);
    const { data } = response;
    return data;
  },
  getRolesByTeam: async (ids) => {
    const response = await request.get(`/admin/internal/search/advanced?teamIds=${ids}`);
    const { data } = response;
    return data;
  },
  adminGetUserById: async (username) => {
    if (!username) return null;

    const response = await request.get(`/admin/${username}`);
    const { data } = response;
    return data;
  },
  adminUpdateUser: async (params) => {
    const response = await request.put(`/admin`, params);
    const { data } = response;
    return data;
  },
  adminEnableUser: async (username) => {
    const response = await request.put(`/admin/enable/${username}`);
    const { data } = response;
    return data;
  },
  adminDisableUser: async (username) => {
    const response = await request.put(`/admin/disable/${username}`);
    const { data } = response;
    return data;
  },
  resetPassword: async (username) => {
    const response = await request.post(`/reset-password/${username}`);
    const { data } = response;
    return data;
  },
  searchUsers: async (params) => {
    const { data } = await request.get(`/admin/search/provider`, { params });
    return data;
  },
  getInternalUsers: async (params) => {
    const { data } = await request.get(`/admin/internal/search`, { params });
    return data;
  },
  adminGetUsers: async (params) => {
    const response = await request.get(`/admin`, { params });
    const { data } = response;
    return data;
  },
  inviteProviderUser: async (id) => {
    const { data } = await request.post(`/admin/providers/${id}/invite`);
    return data;
  },
  activateProviderUser: async (payload) => {
    const { data } = await request.post('/providers/activate', payload);
    return data;
  },
  searchProvidersUsers: async (params) => {
    const { data } = await request.get('/admin/providers/search', { params });
    return data;
  },
};
export { request };
export default api;
