import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';
import { AnimateSharedLayout } from 'framer-motion';
import { AUTH_STATES } from 'constants/index';
import styles from './Header.module.less';
import HeaderMenu from 'components/HeaderMenu';
import SwitchRolesMenu from 'components/SwitchRolesMenu';

const { Header: AntHeader } = Layout;

export default function Header({ className, centerContent, position, boxShadow, theme }) {
  const { authState } = useSelector((state) => state.auth);

  return (
    <AnimateSharedLayout type="crossfade">
      <AntHeader
        data-testid="home-header"
        className={classNames('box-border', { [styles.search]: centerContent }, styles.header, className)}
        style={{ position, bowShadow: boxShadow ? '0 20px 33px 0 rgba(0, 0, 0, 0.05)' : 'none' }}
      >
        <div className="left w-48">
          <div className="h-[64px] w-40">
            <Link to="/" className="home-link block h-[64px]" title="Home">
              <img
                className="logo block h-[64px] w-36"
                src={`/images/${theme === 'primary' ? 'ccaoa-logo' : 'ccaoa-logo'}.png`}
                alt="Logo"
              />
            </Link>
          </div>
        </div>

        {centerContent}

        {!centerContent && (
          <div className="right w-36">
            {authState === AUTH_STATES.LOGGED_IN ? (
              <SwitchRolesMenu theme={theme} />
            ) : (
              <HeaderMenu containerClassName="flex flex-col" theme={theme}>
                dbs
                <MdMenu
                  className={classNames('text-6xl', {
                    'text-primary': theme === 'primary',
                    'text-white': theme !== 'primary',
                  })}
                />
              </HeaderMenu>
            )}
          </div>
        )}
      </AntHeader>
    </AnimateSharedLayout>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(['default', 'primary']),
  centerContent: PropTypes.node,
  position: PropTypes.string,
  boxShadow: PropTypes.bool,
};

Header.defaultProps = {
  className: '',
  position: 'fixed',
  boxShadow: true,
  theme: 'default',
};
