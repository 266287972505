import { Button, Form, Input, message, Tabs, Tooltip, Typography } from 'antd';
import { Auth } from 'aws-amplify';
import ChangeUserName from 'components/Authentication/ChangeUserName';
import ResetPasswordFields from 'components/Authentication/ResetPassword/ResetPasswordFields';
import UserProfile from 'components/Authentication/UserProfile/UserProfile';
import Card from 'components/Card/Card';
import { ROLES } from 'constants';
import { selectActiveRole } from 'features/auth';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useSelector } from 'react-redux';

export default function MyAccount() {
  const activeRole = useSelector(selectActiveRole);
  const isInternalUser = ![ROLES.FAMILY, ROLES.PROVIDER].includes(activeRole);

  const changePassword = useAsyncCallback(async (values) => {
    return (
      Auth.currentAuthenticatedUser()
        // return Auth.signIn(user.email, values.currentPassword)
        .then((user) => {
          return Auth.changePassword(user, values.currentPassword, values.password);
        })
        .then(() => {
          message.success('Password changed successfully');
        })
        .catch((err) => {
          message.error(err.message || 'Unable to change password.');
        })
    );
  });

  const formActions = (
    <div className="actions my-4 mx-8">
      <Button type="primary" htmlType="submit" loading={changePassword.loading} disabled={changePassword.loading}>
        Submit
      </Button>
    </div>
  );

  return (
    <div>
      <Typography.Title level={1} className="!m-0">
        My Account
      </Typography.Title>

      <Tabs
        items={[
          {
            key: 'profile',
            label: 'Profile',
            children: (
              <Card className="mt-8" title="User profile" noBodyPadding>
                <UserProfile />
              </Card>
            ),
          },
          {
            key: 'username',
            label: (
              <Tooltip title={isInternalUser ? 'Internal users cannot change their email address' : undefined}>
                Email address
              </Tooltip>
            ),
            children: (
              <Card className="mt-8" title="Change email address" noBodyPadding>
                <ChangeUserName />
              </Card>
            ),
            disabled: isInternalUser,
          },
          {
            key: 'password',
            label: 'Password',
            children: (
              <Card className="mt-8" title="Change password" noBodyPadding>
                <Form data-testid="change-password-form" layout="vertical" onFinish={changePassword.execute}>
                  {(values, form) => (
                    <>
                      <div className="m-7">
                        <Form.Item
                          rules={[{ required: true, message: 'Enter your current password.' }]}
                          name="currentPassword"
                          label="Current Password"
                        >
                          <Input type="password" placeholder="Current Password" />
                        </Form.Item>
                      </div>

                      <ResetPasswordFields {...values} form={form} actions={formActions} />
                    </>
                  )}
                </Form>
              </Card>
            ),
          },
        ]}
      />
    </div>
  );
}
