import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import App from 'containers/App';
import ErrorBoundary from 'components/ErrorBoundary';
import config from 'config';
import 'styles/tailwind.css';
import 'styles/base.less';
import Authenticator from 'components/Authentication/Authenticator';
import Loading from 'components/Loading';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'focus-visible';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import ReactGA from 'react-ga';

dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

newrelic.addRelease(`gcc-frontend-${config.REACT_APP_ENV}`, config.REACT_APP_RELEASE_ID);

// Google Analytics
ReactGA.initialize(
  {
    dev: 'G-W9ZY1GWVKT',
    staging: 'G-5ESP215KP1',
    prod: 'G-EPHCFPMLFP',
    uat: 'G-0PYZQX9HHF',
  }[process.env.REACT_APP_ENV],
);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: {
      dev: '668c78af36102e10a2b7b8d1',
      staging: '66a7ea6d9b9b730fbbb33fb5',
      prod: '668c78af36102e10a2b7b8d2',
      uat: '66a7ea3bbefd2b10571cd619',
      test: '675c63e2a6ba280944e41a08',
    }[process.env.REACT_APP_ENV],
  });

  ReactDOM.render(
    <LDProvider>
      <ErrorBoundary>
        <ConfigProvider
          theme={{
            cssVar: true,
            token: {
              fontFamily: 'Figtree, sans-serif',
              fontWeightStrong: 500,
              colorPrimary: '#2e5bff',
              lineWidthFocus: 2,
              blue6: '#2e5bff',
              motion: false,
              colorError: '#e00004', // Custom error color
            },
            components: {
              Switch: {
                colorPrimary: '#33ac2e',
              },
              Checkbox: {
                colorPrimary: '#2e5bff',
                lineWidth: 1,
                borderRadius: 0,
                borderRadiusSM: 0,
              },
              Select: {
                controlHeight: 38,
              },
            },
          }}
        >
          <Provider store={store}>
            <React.Suspense fallback={<Loading />}>
              <Authenticator amplifyConfig={config.AMPLIFY_CONFIG} />
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </React.Suspense>
          </Provider>
        </ConfigProvider>
      </ErrorBoundary>
    </LDProvider>,
    document.getElementById('root'),
  );
})();

if (window.Cypress) {
  window.store = store;
}
