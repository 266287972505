import { useCallback } from 'react';
import { message, Button, Modal, Dropdown, Typography, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDown } from '@carbon/icons-react';
import startCase from 'lodash/startCase';
import { selectRoles, actions, selectUser, selectActiveRole } from 'features/auth';
import { ROLES } from 'constants/index';

export default function SwitchRolesMenu() {
  const dispatch = useDispatch();
  const history = useHistory();
  const roles = useSelector(selectRoles);
  const user = useSelector(selectUser);
  const userName = [startCase(user.firstName), startCase(user.lastName)].join(' ');

  const activeRole = useSelector(selectActiveRole);
  const goToDashboard = useCallback(
    async (role) => {
      if (role === ROLES.PROVIDER) {
        history.push('/provider');
      } else if (role === ROLES.SYSTEM_ADMIN) {
        history.push('/admin');
      } else {
        history.push('/dashboard');
      }
    },
    [history],
  );
  const setActiveRole = useCallback(
    (role) => {
      Modal.confirm({
        title: (
          <Typography.Title level={3} className="!m-0">
            Switch Role
          </Typography.Title>
        ),
        content: (
          <>
            <span>
              Are you sure you want to change your role to <b className="font-semibold">{startCase(role)}</b>?
            </span>

            <Divider className="mb-2" />
          </>
        ),
        icon: null,
        okText: 'Change Role',
        width: 550,
        onOk() {
          // Just to show some loaidng before closing Modal
          return new Promise((res) => {
            setTimeout(() => {
              history.push('/'); // this step is required to avoid unexpected api calls with unexpected active role.
              dispatch(actions.setActiveRole(role));
              goToDashboard(role);
              message.success('Role has been updated.', 3);
              res();
            }, 1000);
          });
        },
      });
    },
    [dispatch, goToDashboard, history],
  );

  return (
    <>
      <Dropdown
        menu={{
          selectedKeys: [activeRole],
          items: [
            {
              key: activeRole,
              label: startCase(activeRole),
              onClick: () => {
                goToDashboard(activeRole);
              },
            },
            ...(roles.length > 1
              ? [
                  {
                    key: 'divider_1',
                    type: 'divider',
                  },
                  ...roles
                    .filter((role) => role !== activeRole)
                    .sort()
                    .flatMap((role, index, filteredRoles) => [
                      {
                        key: role,
                        label: startCase(role),
                        onClick: () => setActiveRole(role),
                      },
                    ]),
                ]
              : []),
          ],
        }}
        trigger={['click']}
      >
        <Button
          onClick={(e) => e.preventDefault()}
          data-testid="header-menu-trigger"
          type="text"
          style={{
            color: '#FFFFFF',
          }}
        >
          <ChevronDown />
          {userName}
        </Button>
      </Dropdown>
    </>
  );
}
