import { Typography } from 'antd';
import Form from './Form';

export const MAX_INPUT_PX_WIDTH = 600;
export default Form;

const TEXT_STYLES = {
  STYLED: {
    color: '#393939',
    fontFamily: 'Figtree',
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: '1.29px',
  },
  UNSTYLED: {
    display: 'block',
    fontSize: '14px',
    fontWeight: 'normal',
    textTransform: 'none',
    letterSpacing: '0px',
    wordSpacing: '1px',
    textIndent: '0',
    whiteSpace: 'normal',
  },
};

export const FormItemLabel = ({ title, unstyledTitle, subTitle, showAsterisk }) => (
  <span>
    {showAsterisk && <span className="text-red-500 mr-1">*</span>}

    <Typography.Text style={unstyledTitle ? TEXT_STYLES.UNSTYLED : TEXT_STYLES.STYLED}>{title}</Typography.Text>

    <Typography.Text type="secondary" style={TEXT_STYLES.UNSTYLED}>
      {subTitle}
    </Typography.Text>
  </span>
);

export const DatePickerFormItemLabel = ({ title }) => <FormItemLabel title={title} subTitle="mm/dd/yyyy" />;
