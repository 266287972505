import { Button, Divider, Form, Input, message } from 'antd';
import FormField from '../FormField';
import Space, { Spacer } from 'components/Space/Space';
import { actions, selectActiveRole, selectUser } from 'features/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ROLES } from 'constants';
import { userService } from 'services';
import { SINGLE_REGEX } from 'constants';
import { NAME_REGEX } from 'constants';

export default function UserProfile() {
  const currentUser = useSelector(selectUser);
  const activeRole = useSelector(selectActiveRole);
  const dispatch = useDispatch();

  const isProvider = activeRole === ROLES.PROVIDER;

  const [form] = Form.useForm();
  useEffect(() => {
    console.log(currentUser);

    if (currentUser) {
      form.setFieldsValue({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        businessName: currentUser.businessName,
      });
    }
  }, [currentUser, form]);

  const [userUpdating, setUserUpdating] = useState(false);
  const onFinish = async (values) => {
    setUserUpdating(true);

    try {
      const _updatedUser = await userService.updateUser(currentUser.id, { ...currentUser, ...values });

      message.success('User updated successfully');

      dispatch(actions.updateUser(_updatedUser));
      form.setFieldsValue({
        firstName: _updatedUser.firstName,
        lastName: _updatedUser.lastName,
        businessName: _updatedUser.businessName,
      });
    } catch (error) {
      console.log('Failed to update user', error);
      message.error('Failed to update user');
    } finally {
      setUserUpdating(false);
    }
  };

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="m-7">
          <FormField
            label="First name"
            name="firstName"
            rules={[
              { required: true, message: 'First name is required' },
              () => ({
                validator(rule, value) {
                  if (typeof value === 'string' && value && !value.trim()) return Promise.reject('Invalid first name');
                  if (!value || SINGLE_REGEX.test(value) || NAME_REGEX.test(value)) {
                    return Promise.resolve();
                  }

                  return Promise.reject('Invalid first name');
                },
              }),
            ]}
            className="max-w-[800px]"
          >
            <Input placeholder="First name" />
          </FormField>

          <FormField
            label="Last name"
            name="lastName"
            rules={[
              { required: true, message: 'Last name is required' },

              () => ({
                validator(rule, value) {
                  if (typeof value === 'string' && value && !value.trim()) return Promise.reject('Invalid last name');
                  if (!value || SINGLE_REGEX.test(value) || NAME_REGEX.test(value)) {
                    return Promise.resolve();
                  }

                  return Promise.reject('Invalid last name');
                },
              }),
            ]}
            className="max-w-[800px]"
          >
            <Input placeholder="Last name" />
          </FormField>

          {isProvider && (
            <FormField
              label="Org/Business Name"
              name="businessName"
              rules={[{ required: true, message: 'Org/Business Name is required' }]}
              className="max-w-[800px]"
            >
              <Input placeholder="Org/Business Name" />
            </FormField>
          )}
        </div>

        <Divider className="mb-0" />

        <Space className="my-4 mx-8">
          <Spacer />

          <Button htmlType="submit" type="primary" loading={userUpdating}>
            Save
          </Button>
        </Space>
      </Form>
    </div>
  );
}
