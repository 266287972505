import { FileList } from 'components/FileList/FileList';
import TabularList from 'components/TabularList/TabularList';
import { PROVIDER_FILE_TYPES } from 'services/providers.service';

export function NavyHccDocumentDetails({ applicationFiles, careFacilityFiles, orgFiles }) {
  const careFacilityAndOrgFiles = [...(careFacilityFiles || []), ...(orgFiles || [])];

  const _applicationSummaryFiles = applicationFiles?.filter(
    (file) => file?.fileType === PROVIDER_FILE_TYPES.APPLICATION_SUMMARY.code,
  );
  const _discountVerificationFiles = applicationFiles?.filter(
    (file) => file?.fileType === PROVIDER_FILE_TYPES.DISCOUNT_VERIFICATION.code,
  );
  const _w9Files = careFacilityAndOrgFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.W9.code);
  const _careLocationW9Files = careFacilityFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.W9.code);
  const _einCertificateFiles = careFacilityAndOrgFiles?.filter(
    (file) => file?.fileType === PROVIDER_FILE_TYPES.EIN.code,
  );
  const _voidedCheckOrBankLetterFiles = careFacilityAndOrgFiles?.filter(
    (file) => file?.fileType === PROVIDER_FILE_TYPES.DIRECT_DEPOSIT.code,
  );
  const _licenseFiles = careFacilityFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.STATE_LICENSE.code);
  const _firstAidOrCprCertificationFiles = applicationFiles?.filter(
    (file) => file?.fileType === PROVIDER_FILE_TYPES.CERTIFICATION.code,
  );
  const _otherFiles = applicationFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.OTHER.code);

  return (
    <TabularList
      className="mt-8"
      data={[
        {
          title: 'Application Summary',
          value: <FileList files={_applicationSummaryFiles} />,
        },
        {
          title: 'Discount Verification',
          value: <FileList files={_discountVerificationFiles} />,
        },
        {
          title: 'W-9',
          titleRequired: true,
          value: <FileList files={_w9Files} />,
        },
        {
          title: 'Care location W-9',
          titleRequired: true,
          value: <FileList files={_careLocationW9Files} />,
        },
        {
          title: 'EIN Certificate',
          value: <FileList files={_einCertificateFiles} />,
        },
        {
          title: 'Voided check or bank letter',
          value: <FileList files={_voidedCheckOrBankLetterFiles} />,
        },
        {
          title: 'Licenses',
          titleRequired: true,
          value: <FileList files={_licenseFiles} />,
        },
        {
          title: 'First aid/CPR certifications',
          value: <FileList files={_firstAidOrCprCertificationFiles} />,
        },
        {
          title: 'Other documents',
          value: <FileList files={_otherFiles} />,
        },
      ]}
    />
  );
}
